.breadcrumb {
  list-style-type: none;
  overflow: hidden;
  margin: 0;
  padding: 7px 15px 7px;
  color: var(--wl-text-color-five);
  font-size: var(--wl-font-size-xxx-small);
}

.breadcrumb li {
  float: left;
}

.breadcrumb li a {
  color: var(--wl-text-color-five);
}

.breadcrumb li .divider {
  color: var(--wl-text-color-five);
  padding: 0 6px;
}

.breadcrumb li .divider-mobile {
  color: var(--wl-text-color-five);
  padding: 0 5px;
  display: none;
}

.breadcrumb li:not(:first-child) {
  text-transform: capitalize;
}

.breadcrumb li.make {
  text-transform: none;
}
