footer.main-footer {
  background-color: var(--wl-footer-background-color);
}

footer.main-footer a {
  color: var(--wl-footer-link-color);
}

footer.main-footer ul {
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-default);
  margin: 0 0 30px;
  list-style: none;
  padding: 0;
}

footer.main-footer ul a {
  display: block;
  font-weight: normal;
  padding: 3px 0;
}

footer.main-footer nav.footer-disclaimers {
  padding: 20px 12px 12px;
  margin: 0 auto;
  text-align: center;
}

footer.main-footer hr {
  height: 1px;
  background-color: var(--wl-border-color-default);
  border: none;
  margin: 0;
}

footer.main-footer nav.footer-disclaimers .copyright-info {
  color: var(--wl-footer-text-color);
  font-size: var(--wl-font-size-default);
}

footer.main-footer nav.footer-disclaimers ul {
  padding: 15px 0;
}

footer.main-footer ul a .adchoices-icon {
  width: 14px;
  padding-left: 4px;
  vertical-align: middle;
}

.home-quicklinks-container .collapse-content {
  border-bottom: 1px solid var(--wl-border-color-eleven);
  margin: 0;
}

@media screen and (min-width: 500px) {
  footer.main-footer ul {
    margin-right: 1%;
  }

  footer.main-footer nav.footer-disclaimers {
    margin: 0;
  }

  footer.main-footer nav.footer-disclaimers ul {
    width: 100%;
    margin: 0;
  }

  footer.main-footer nav.footer-disclaimers ul li {
    display: inline-block;
    margin-left: 10px;
  }
}

@media screen and (min-width: 1360px) {
  footer.main-footer nav.footer-disclaimers {
    overflow: hidden;
  }

  footer.main-footer nav.footer-disclaimers .copyright-info {
    width: auto;
  }

  footer.main-footer nav.footer-disclaimers ul {
    padding: 0;
    width: auto;
  }
}

/* SEO CONTENT */
.footer-links {
  background-color: var(--wl-content-background-color-default);
  border-top: 1px solid var(--wl-border-color-default);
  width: 100%;
}

.footer-links-container {
  color: var(--wl-text-color-ten);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  max-width: var(--wl-max-content-width);
  font-size: var(--wl-font-size-medium);
  line-height: var(--wl-line-height-medium);
  margin: 0 auto;
  padding-left:0;
  padding-right:0;
}

.footer-links-container div{
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.footer-links-container .quicklink-list {
  display: flex;
  flex-basis: 25%;
  justify-content: flex-start;
  width: auto;
}

.footer-links-container .quicklink-list h2{
  color: var(--wl-text-color-one);
  font-weight: bold;
  font-size: var(--wl-font-size-large);
  padding: 0;
}

.footer-links-container .quicklink-list ul{
  margin: 0px;
  padding-inline-start: 0;
}

.footer-links-container .quicklink-list li {
  list-style: none;
}

.footer-links-container .quicklink-list li a{
  color: var(--wl-text-color-ten);
  line-height: var(--wl-line-height-x-large);
}

.footer-links-container .quicklink-list li a:hover,
.footer-links-container .quicklink-list li a:focus {
  color: var(--wl-text-color-eleven);
  text-decoration: underline;
}

@media screen and (max-width: 500px) {
  .footer-links-container {
    display: inline-block;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .footer-links-container .quicklink-list {
    display: flex;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .footer-links-container {
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px;
  }

  footer.main-footer nav.footer-disclaimers {
    margin: 0 auto;
    display: flex;
    overflow: hidden;
    justify-content: center;
  }

  footer.main-footer nav.footer-disclaimers:has(div:nth-child(2)) {
    justify-content: space-between;
  }

  footer.main-footer nav.footer-disclaimers div.footer-top {
    flex-grow: 1;
  }
}

@media screen and (min-width: 700px) {
  footer.main-footer nav.footer-disclaimers ul {
    display: inline;
  } 
}

div.upper-footer {
  background-color: #2d3e4f;
  width: 100%;
}

div.upper-footer a:link {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-right: 20px;
  text-decoration: none;
  text-transform: capitalize;
}

div.upper-footer a:hover {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  margin-right: 20px;
  text-decoration: none;
  text-transform: capitalize;
}

div.upper-footer .inner {
  text-align: left;
  margin: 0 auto;
  padding: 15px 26px 15px 36px;
  font-family: "Open Sans","Helvetica Neue",Helvetica,sans-serif;
  color: white;
}

@media screen and (max-width: 767px) {
  div.upper-footer .inner {
    display: block;
  }
  div.upper-footer .inner a:link {
    display: block;
    text-align: center;
    margin-bottom: 8px;
  }

  div.upper-footer .inner .socials-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }

  footer.main-footer nav.footer-disclaimers {
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  div.upper-footer .inner .socials-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }

  .home-block div.upper-footer .inner, .home-block .main-footer .footer-disclaimers {
    max-width: 1415px ;
  }

  div.upper-footer .socials-footer a:link {
    margin-right: 0;
    margin-left: 20px;
  }
}
.social-link-x img {
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 1233px) {
  div.upper-footer .inner {
    display:flex;
  }
  div.upper-footer .inner .socials-footer {
    margin-left: auto;
    margin-right: 0px;
  }
}
