/* base styles */

body, html, input, button, textarea {
  font-family: var(--wl-font-family-default);
}

body, html {
  margin: 0;
  font-size: var(--wl-font-size-default);
  line-height: var(--wl-line-height-default);
  color: var(--wl-text-color-default);
  height: 100%;
}

a {
  text-decoration: none;
}

img {
  font-size: 0;
  max-width: 100%;
}

/* Iconography */

[class^="icon-"], [class*=" icon-"] {
  background: none;
  display: inherit;
  height: auto;
  line-height: inherit;
  margin-top: auto;
  vertical-align: inherit;
  width: inherit;
}

/* page container */

.page-container > .content.nav-slide {
  margin-top: 0;
  background-color: var(--wl-content-background-color-default);
}

.page-container > .content.nav-slide.with-sticky-contact {
  padding-bottom: var(--wl-height-sticky-footer);
}

@media screen and (min-width: 970px), (max-width: 767px) {

  .page-container > .content.nav-slide.with-sticky-contact {
    padding-bottom: 0;
  }
}

/* Used to hide content for accessibility purposes */
.visuallyhidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.truste-messageColumn, .truste-buttonsColumn, #truste-consent-button, #truste-consent-required, #truste-show-consent {
  font-family: var(--wl-font-family-default) ! important;
  font-size: var(--wl-font-size-default) ! important;
}

.component-uuid {
  display: none;
}
