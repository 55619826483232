.app-banner-top {
  display: none;
  position: absolute;
  z-index: 99999;
  width: 100%;
  background: var(--wl-background-color-fourteen);
  padding: 10px 15px;
  box-sizing: border-box;
  align-items: center;
  font-family: var(--wl-font-family-default);
  height: 50px;
  top: 0;
  left: 0;
}

.app-banner-top.is-visible {
  display: flex;
}

.app-banner__logo-top {
  margin-left: 15px;
  width: 32px;
  height: 32px;
}

.app-banner__text-top {
  margin-left: 10px;
  margin-right: 10px;
}

.app-banner__title {
  display: block;
  font-size: var(--wl-font-size-xx-small);
  color: var(--wl-text-color-fourteen);
  font-weight: bold;
  line-height: var(--wl-font-size-small);
}

.app-banner__sub-title-top {
  display: block;
  font-size: var(--wl-font-size-xx-small);
  color: var(--wl-text-color-fourteen);
  font-weight: 400;
  line-height: var(--wl-font-size-small);
}

.app-banner__button-top {
  margin-left: auto;
  font-weight: bold;
  color: var(--wl-content-background-color-default);
  padding: 6px 10px;
  border: 0;
  background: var(--wl-background-color-fifteen);
  border-radius: 4px;
  font-size: var(--wl-font-size-xx-small);
  line-height: var(--wl-font-size-small);
  cursor: pointer;
}

.app-banner-overlay {
  display: none;
  position: fixed;
  z-index: 99999;
  width: 100%;
  background: var(--wl-background-opaque-default);
  padding: 16px;
  box-sizing: border-box;
  align-items: center;
  font-family: var(--wl-font-family-default);
  height: 280px;
  bottom: 0;
}

.app-banner-overlay.is-visible {
  display: flex;
  flex-direction: column;
}

.app-banner__logo-overlay {
  margin-top: 8px;
  height: 96px;
  width: 96px;
}

.app-banner__text-overlay {
  margin-top: 12px;
  text-align: center;
}

.app-banner__sub-title-overlay {
  display: block;
  font-size: var(--wl-font-size-xx-small);
  color: var(--wl-text-color-fourteen);
  font-weight: 400;
  line-height: var(--wl-font-size-small);
  margin-top: 4px;
}

.app-banner__button-overlay {
  font-weight: bold;
  color: var(--wl-content-background-color-default);
  padding: 8px 0;
  border: 0;
  background: var(--wl-background-color-fifteen);
  border-radius: 4px;
  font-size: var(--wl-font-size-small);
  line-height: var(--wl-font-size-small);
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  text-align: center;
}

span.app-banner__close {
  color: var(--wl-text-color-link);
  cursor: pointer;
  font-size: var(--wl-font-size-xx-small);
  margin-top: 10px;
  text-decoration: underline;
}

.animate {
  top: 50px !important;
}
