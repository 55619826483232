div.lang-wrapper {
  display: flex;
  right: 0;
  top: 0;
  z-index: 2001;
  padding: 0px 15px;
  color: var(--wl-header-text-color);
  border-left: 1px solid var(--wl-background-color-default);
  white-space: nowrap;
}

.lang-switcher {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 51px;
  line-height: 51px;
  object-fit: contain;
}

.flag {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 16px;
}

.lang-menu .flag {
  margin-right: .5em;
}

.flag.US{
  background-image: url(./US.svg);
}

.flag.CA{
  background-image: url(./CA.svg);
}

.flag.ES{
  background-image: url(./ES.svg);
}

.flag.FR{
  background-image: url(./FR.svg);
}

.flag.DK{
  background-image: url(./DK.svg);
}

.flag.DE{
  background-image: url(./DE.svg);
}

.flag.IT{
  background-image: url(./IT.svg);
}

.flag.NL{
  background-image: url(./NL.svg);
}

.flag.NO{
  background-image: url(./NO.svg);
}

.flag.FI{
  background-image: url(./FI.svg);
}

.flag.SE{
  background-image: url(./SE.svg);
}

.flag.UK{
  background-image: url(./UK.svg);
}

.flag.HR{
  background-image: url(./HR.svg);
}

.flag.GR{
  background-image: url(./GR.svg);
}

.flag.PL{
  background-image: url(./PL.svg);
}

.flag.TR{
  background-image: url(./TR.svg);
}

div.lang-switcher > span {
  display: none;
}

.lang-menu {
  display: flex;
  flex-direction: column;
  background: var(--wl-content-background-color-default);
  box-shadow: 0 10px 10px var(--wl-box-shadow-two);
  position: absolute;
  top: 60px;
  right: 10px;
  width: max-content;
}

.lang-menu::before {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 6px solid var(--wl-content-background-color-default);
  top: -6px;
  content: "";
  height: 0;
  left: 90%;
  position: absolute;
  width: 0;
}

.lang-menu-hide {
  display: none;
}

a.lang-item {
  display: flex;
  padding: 15px;
  text-align: left;
  color: var(--wl-text-color-default);
  line-height: var(--wl-line-height-default);
}

a.lang-item:hover {
  background-color: var(--wl-background-color-default);
}

div.lang-wrapper.new-header {
  border: none;
}

/* New header */
.new-header .flag {
  height: 13px;
  width: 13px;
  background-size: cover;
  border-radius: 15px;
  border: solid 2px var(--wl-border-color-three);
}

.new-header .lang-switcher > .flag:after {
  border: solid var(--wl-border-color-three);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  content: "";
  height: 2px;
  width: 2px;
  transform: rotate(45deg);
  margin-left: 20px;
  margin-bottom: 20px;
}

.new-header .lang-menu {
  right: auto;
}

.new-header .lang-menu::before {
  left: 18px;
}

@media screen and (min-width: 970px) {
  .lang-menu {
    right: auto;
    margin-right: -10px;
  }

  div.lang-wrapper {
    align-items: center;
    padding: 0 10px 0 0;
    min-height: 62px;
    border: none;
    justify-content: flex-end;
  }

  div.lang-switcher > span {
    display: block;
    margin-left: .5em;
  }

  /* New header */
  .new-header div.lang-switcher > span {
    display: none;
  }

  .new-header .lang-menu {
    margin-right: -18px;
  }

  .new-header .lang-menu:before {
    left: auto;
    right: 0px;
  }
}
