.react-jw-player-container {
  display: flex;
  height: 100%;
  margin: 0 4em 0;
  width: 100%;
}

.react-jw-player-container > div {
  height: inherit;
  width: inherit;
  max-height: 100%;
  max-width: 100%;
}

.react-jw-player-container > div > div.jwplayer.jw-flag-aspect-mode {
  height: inherit !important;
  width: inherit;
}