.content {
  background-color: var(--wl-background-color-default);
  clear: both;
}

.disable-actions {
  pointer-events: none;
  opacity: 0.5;
}

.disable-actions:has(.mobile-search-filters) {
  opacity: unset;
}

