.cookie-message {
  position: fixed;
  background: #000000db;
  bottom: 0;
  left: auto;
  top: auto;
  transform: initial;
  width: 100%;
  z-index: 9999;
  box-sizing: border-box;
}

.cookie-close {
  width: auto;
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 2px 4px 2px 4px;
  cursor: pointer;
  border: none;
  height: 30px;
  width: 30px;
  background: url(//static/media/ModalCloseButton.b9e27386.svg) no-repeat 50%;
}

.cookie-close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.cookie-message-container {
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cookie-message-text-wrapper {
  /* display: flex; */
  /* flex-direction: row; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* flex-shrink: 0; */
  /* flex-flow: nowrap; */
}

.cookie-message-text {
  color: #fff;
  width: auto;
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}

.cookie-message-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  flex-flow: nowrap;
}

.cookie-message-link {
  color: #fff;
  font-weight: bold;
  text-decoration: underline;
}

.cookie-message-button {
  color: #000;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  white-space: nowrap;
  box-shadow: none;
  border: none;
  text-shadow: none;
  -webkit-appearance: none;
  width: 100%;
}

.cookie-message-button:first-of-type {
  margin: 0 20px;
  background: transparent;
  color: white;
  border: 1px solid white;
}

.cookie-message:not(.show),
.cookie-message > div:not(.cookie-message-container) {
  display: none;
}

@media only screen and (max-width: 1439px) {
  .cookie-message-button-wrapper {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 0 20px;
  }

  .cookie-message-button:first-of-type {
    margin: 20px 0 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .cookie-message-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .cookie-message-button-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin: 10px 0 0 0;
    width: 100%;
  }

  .cookie-message-button:first-of-type {
    margin: 0 20px 0 0;
  }

  @-webkit-keyframes slideIn {
    from {
      margin-bottom: -260px;
    }
    to {
      margin-bottom: 0px;
    }
  }
  @keyframes slideIn {
    from {
      margin-bottom: -260px;
    }
    to {
      margin-bottom: 0px;
    }
  }

  .cookie-message {
    position: fixed;
    background: #000000db;
    bottom: 0;
    left: auto;
    top: auto;
    transform: initial;
    width: auto;
    z-index: 9999;
    box-sizing: border-box;
    border-radius: 16px 16px 0px 0px;
    -webkit-animation: slideIn 0.4s;
    animation: slideIn 0.4s;
    animation-timing-function: ease;
  }

  .cookie-message-text-wrapper .read-more-target {
    opacity: 0;
    max-height: 0;
    font-size: 0;
    transition: 0.25s ease;
  }

  .cookie-message-text-wrapper .read-more-target.show {
    opacity: 1;
    font-size: inherit;
    max-height: 999em;
  }

  .read-more-trigger {
    cursor: pointer;
    display: inline-block !important;
    padding: 0 0.5em;
    color: white;
    text-decoration: underline;
    font-size: 0.9em;
    line-height: 2;
  }
}
