.newsletter-subscription {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--wl-background-color-eighteen);
  height: 100%;
  padding: 25px 15px;
  width: 100%;
}

.newsletter-subscription.disabled {
  background-color: var(--wl-button-disabled-background);
}

.newsletter-icon {
  display: grid;
  justify-content: center;
}

.newsletter-icon:before {
  content: '';
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  height: 78px;
  width: 97.5px;
}

.newsletter-envelope:before {
  background-image: url(../../../../icons/envelope.svg);
  background-size: contain;
}

.newsletter-message {
  color: var(--wl-text-color-sixteen);
  display: inline-block;
  font-size: var(--wl-font-size-xx-large);
  font-style: normal;
  font-weight: 700;
  line-height: var(--wl-line-height-xx-large);
  letter-spacing: 0em;
  margin-top: 25px;
  text-align: center;
}

.newsletter-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.newsletter-email-container {
  margin-top: 25px;
  max-width: 550px;
  min-width: 300px;
  width: 50%;
}

.newsletter-email-container .flexbox {
  display: flex;
  height: 36px;
}

.newsletter-email-container .flexbox input {
  width: 0;
  margin: 0;
  padding: 0 8px;
  border: 1px solid var(--search-alerts-modal-input-border-color);
  border-radius: 5px 0px 0px 5px;
  font-size: var(--wl-font-size-medium);
  flex-grow: 1;
}

.newsletter-email-container .flexbox input:focus {
  outline: none;
}

.newsletter-email-container .flexbox input::placeholder {
  font-size: var(--wl-font-size-medium);
  color: var(--search-alerts-modal-placeholder-color);
}

.newsletter-email-container .flexbox button {
    margin: 0;
    padding: 0px 12.5px 0px 13px;
    font-size: var(--wl-font-size-medium);
    color: var(--wl-text-color-four);
    background: var(--wl-background-color-one);
    border: none;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
}

.newsletter-email-container .flexbox button:disabled {
    background: var(--wl-text-color-link);
    cursor: default;
}

.newsletter-sign-up {
  color: var(--wl-text-color-sixteen);
  cursor: pointer;
  display: block;
  font-size: var(--wl-font-size-small);
  font-style: normal;
  font-weight: 400;
  line-height: var(--wl-line-height-small);
  letter-spacing: 0em;
  margin-top: 25px;
  padding-left: 27px;
  padding-top: 2px;
  position: relative;
  text-align: left;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.newsletter-sign-up input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}

.checkmark {
  background-color: var(--wl-content-background-color-default);
  border-radius: 2px;
  left: 0;
  height: 20px;
  position: absolute;
  top: 0;
  width: 20px;
}

.newsletter-sign-up:hover input ~ .checkmark {
  background-color: var(--wl-border-color-default);
}

/* .newsletter-sign-up input:checked ~ .checkmark {
  background-color: var(--wl-content-background-color-default);
} */

.checkmark.checked {
  background-color: var(--wl-content-background-color-default);
}

.checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

/* .newsletter-sign-up input:checked ~ .checkmark:after {
  display: block;
} */

.checkmark.checked:after {
  display: block;
}

.newsletter-sign-up .checkmark:after {
  border: solid var(--wl-background-color-eighteen);
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 6px;
  top: 3px;
  transform: rotate(45deg);
  width: 5px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.newsletter-agreement {
  color: var(--wl-text-color-sixteen);
  font-size: var(--wl-font-size-xx-small);
  font-style: normal;
  font-weight: 400;
  line-height: var(--wl-line-height-xx-small);
  letter-spacing: 0em;
  margin-top: 25px;
  text-align: center;
}

.newsletter-agreement a {
  color: var(--wl-text-color-sixteen) !important;
  text-decoration: underline;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@media screen and (min-width: 1400px) {
  .newsletter-message {
    max-width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .newsletter-message {
    font-size: var(--wl-font-size-medium);
    line-height: var(--wl-line-height-medium);
  }
}