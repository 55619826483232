.confirmation-modal {
  --search-alerts-confirmation-modal-window-bg: var(
    --wl-content-background-color-default
  );
  --search-alerts-confirmation-modal-check-bg: var(
    --wl-background-color-fifteen
  );
}

.confirmation-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
}

.confirmation-modal {
  width: 325px;
  height: 50px;
  position: absolute;
  left: 50%;
  top: 50px;
  transform: translate(-50%, -50%);
  background-color: var(--search-alerts-confirmation-modal-window-bg);
  box-shadow: 0px 2px 10px 1px rgba(99, 99, 99, 0.66);
}

.confirmation-modal:focus {
  outline: none;
}

.confirmation-modal .check {
  background-color: var(--search-alerts-confirmation-modal-check-bg);
  display: grid;
  float: left;
  height: 100%;
  justify-content: center;
  width: 60px;
}

.confirmation-modal .check:before {
  content: '';
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  height: 50px;
  width: 25px;
}

.confirmation-modal .check-icon {
  position: relative;
}

.confirmation-modal .check-icon::before {
  background-image: url(../../icons/Check.svg);
  background-size: contain;
}
.confirmation-modal .container {
  height: 100%;
  display: flex;
  padding: 0px 16px;
  align-items: center;
}

.confirmation-modal .container .message {
  font-size: var(--wl-font-size-medium);
  flex-grow: 1;
}

.confirmation-modal .container .close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: grid;
  justify-content: center;
  padding: 0;
}

.confirmation-modal .container .close-button:before {
  content: '';
  background-repeat: no-repeat;
  background-origin: content-box;
  background-position: center;
  height: 20px;
  width: 20px;
}
.confirmation-modal .container .close-icon:before {
  background-image: url(../../icons/Close.svg);
  background-size: contain;
}
