.error-msg-content {
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-msg {
  display: flex;
  flex-direction: column;
}